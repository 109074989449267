import * as React from "react";
import Typewriter from "typewriter-effect";
import { heroContainer } from "./Hero.module.css";

const Hero = () => {
  return (
    <div
      className="grid"
      style={{
        background: `radial-gradient(circle, rgb(34, 197, 94) 0%, rgb(139, 92, 246) 100%)`,
      }}
    >
      <div className={`mx-auto max-w-7xl lg:px-8 ${heroContainer}`}>
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="mx-auto max-w-md px-4 md:max-w-2xl sm:text-center lg:px-0 lg:text-left lg:flex">
            <div className="lg:py-40">
              <h1 className="mt-40  text-3xl tracking-tight font-extrabold text-white md:text-5xl lg:mt-6 xl:text-6xl">
                <span className="block">A fast site means</span>
                <Typewriter
                  options={{
                    strings: [
                      "increased sales.",
                      "better SEO.",
                      "customer retention.",
                      "happier users.",
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </h1>
              <p className="mt-3 text-base text-gray-800 lg:text-lg xl:text-xl">
                Whether you're looking to launch a hot new ecommerce store,
                start a viral blog, or anything in between - we'll deliver
                something that looks great and performs even better.
              </p>
            </div>
          </div>
          <div className="mt-6 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
            <div className="mx-auto max-w-md px-4 sm:px-6 lg:max-w-none lg:px-0">
              <img
                className="mx-auto my-6"
                src="hero-small.svg"
                alt="A podium with your application in first place."
                width={500}
                height={500}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
