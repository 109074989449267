import * as React from "react";

const LogoCloud = () => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <span className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              We use lightweight frameworks such as
            </span>
            <img
              className="inline max-h-8"
              style={{ margin: `-15px 5px 0 8px` }}
              src={"/gatsby-logo.svg"}
              height={32}
              width={121}
              alt="Statamic"
            />{" "}
            <span className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              and
            </span>
            <img
              className="inline max-h-10"
              style={{ margin: `-14px 6px 0 10px` }}
              src={"/nextjs-logo.svg"}
              height={40}
              width={67}
              alt="Statamic"
            />{" "}
            <span className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              to make sure your site is{" "}
              <span className="text-green-600">fast by default.</span>
            </span>
            <p className="mt-3 max-w-3xl text-lg text-gray-500">
              Companies like these also have also trusted the same Jamstack to
              power their digital businesses.
            </p>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img
                className="max-h-12"
                src={"/netflix-3.svg"}
                height={48}
                width={177}
                alt="Workcation"
              />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img
                className="max-h-12"
                src={"/facebook-5.svg"}
                height={48}
                width={184}
                alt="Mirage"
              />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img
                className="max-h-12"
                src={"/paypal-2.svg"}
                height={48}
                width={180}
                alt="Tuple"
              />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img
                className="max-h-12"
                src={"/nike.svg"}
                height={48}
                width={133}
                alt="Laravel"
              />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img
                className="max-h-12"
                src={"/tiktok.svg"}
                height={48}
                width={166}
                alt="StaticKit"
              />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img
                className="max-h-12"
                src={"/twitch-logo-2019.svg"}
                height={48}
                width={144}
                alt="Statamic"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoCloud;
