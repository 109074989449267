import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Cta = () => {
  return (
    <div className="bg-white">
      <div className="bg-purple-800">
        <div className="shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">
                  Ready to build a championship winning website?
                </span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-indigo-200">
                We challenge you to click around the links of our site as fast
                as you can. Feel the difference of an EnvizionLabs website with
                lightning quick page transitions and load times.
              </p>
              <Link
                to="/contact-us/"
                className="mt-8 bg-white border border-transparent rounded-full shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50"
              >
                Get Started
              </Link>
            </div>
          </div>
          <div className="mb-8 md:p-8 md:mb-0">
            <StaticImage
              className="md:rounded-lg"
              src="../../images/lighthouse-scores.png"
            ></StaticImage>
            <p className="block text-white text-center mt-2 px-2">
              <i>Did you know?</i>
            </p>
            <p className="block text-white text-center px-2">
              A fast loading site will rank higher in Google Search results
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;
