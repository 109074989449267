import * as React from "react";
import Seo from "../components/Seo";
import Hero from "../components/Hero";
import LogoCloud from "../components/LogoCloud";
import Cta from "../components/Cta";

const IndexPage = () => {
  return (
    <>
      <Seo title="A creative agency with a focus on speed" />
      <div>
        <Hero />
      </div>
      <LogoCloud />
      <Cta />
    </>
  );
};

export default IndexPage;
